// 2019-10-27 15:37:53
import React from "react";
// import { useRef } from "react";
// import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const Dot = styled.a`
  display: inline-block;
  width: 1px;
  height: 1px;
  background-color: black;
`;

const Stowaway = () => {
  return (
    <Layout>
      <SEO title="Tiny Link"></SEO>
      <p>
        Click the dot to continue: → <Dot href="/"></Dot>
      </p>
    </Layout>
  );
};

export default Stowaway;
